import { Menu, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  
  // ✅ Updated to check active hash route (ex: #about, #tokenomics)
  const isActive = (hash) => {
    if (!location.hash && hash === "/") return true; // Home
    return location.hash === hash;
  };

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 px-6 py-4 flex justify-between items-center transition-all duration-300 ${
        isScrolled ? "bg-[#0b0f1a] shadow-md" : "bg-transparent"
      }`}
    >

      {/* Logo */}
      <div className="flex items-center">
        <a href="#tagline">
          <img 
            src="/logo.png" 
            alt="BMAXAI Logo" 
            className="h-10 w-auto cursor-pointer" 
          />
        </a>
      </div>

      {/* Nav */}
      <nav className="relative flex items-center">
        <button
          className="lg:hidden text-white"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
        </button>

        <ul
          className={`flex flex-col lg:flex-row gap-4 lg:gap-6 absolute lg:static top-16 right-4 bg-[#0b0f1a] lg:bg-transparent text-white p-4 rounded-xl lg:p-0 transition-all duration-300 ${
            isMenuOpen ? "flex" : "hidden"
          } lg:flex`}
        >
          <li>
            <a 
              href="/" 
              className={`px-3 py-1 rounded-md transition duration-200 hover:bg-blue-500/40 hover:text-green-500 ${
                isActive("/") ? "text-green-500" : ""
              }`}
            >
              Home
            </a>
          </li>
          <li>
            <a 
              href="#about" 
              className={`px-3 py-1 rounded-md transition duration-200 hover:bg-blue-500/40 hover:text-green-500 ${
                isActive("#about") ? "text-green-500" : ""
              }`}
            >
              About
            </a>
          </li>
          <li>
            <a 
              href="#tokenomics" 
              className={`px-3 py-1 rounded-md transition duration-200 hover:bg-blue-500/40 hover:text-green-500 ${
                isActive("#tokenomics") ? "text-green-500" : ""
              }`}
            >
              Tokenomics
            </a>
          </li>
          <li>
            <a 
              href="#roadmap" 
              className={`px-3 py-1 rounded-md transition duration-200 hover:bg-blue-500/40 hover:text-green-500 ${
                isActive("#roadmap") ? "text-green-500" : ""
              }`}
            >
              Roadmap
            </a>
          </li>
          <li>
            <a 
              href="https://pancakeswap.finance/swap?outputCurrency=0x019d1CaBA988cE86b4067615D5069759e353b86B" 
              target="_blank" 
              rel="noreferrer"
              className="bg-pink-600 hover:bg-pink-700 text-white px-4 py-1 rounded-xl"
            >
              Buy $BMAXAI
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;