import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-10 px-4 mt-16">
      <div id="tagline" className="max-w-6xl mx-auto text-center border-t border-gray-800 pt-10">
        <a href="#tagline">
          <h3 className="text-2xl font-bold text-yellow-500 mb-2 tracking-wider cursor-pointer hover:no-underline">
            $BMAXAI
          </h3>
        </a>
        <p className="text-sm text-gray-400 mb-6 italic">
          Meme-powered. AI-infused. Built by a non-tech weirdo, for the future.
        </p>

        <div className="flex justify-center gap-4 mb-4">
          <a href="https://t.me/bmaxai" target="_blank" rel="noreferrer">Telegram</a>
          <a href="https://x.com/bmax_ai" target="_blank" rel="noreferrer">X</a>
          <a href="#about">About</a>
        </div>

        <p className="text-xs text-gray-500">
          © {new Date().getFullYear()} BMAXAI. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;