//src/pages/Homepage.js

import React from 'react';

const HomePage = () => {
  return (
    <div className="bg-black min-h-screen text-white scroll-smooth">
      {/* Banner Section */}
      <div className="w-full">
        <img src="/banner.png" alt="BMAXAI Banner" className="w-full object-cover" />
      </div>

      {/* Tagline Section */}
      <div id="tagline" className="scroll-mt-36 text-center mt-20 px-4">
        <h1 className="text-5xl font-bold mb-4 text-yellow-500">$BMAXAI</h1>
        <p className="text-xl max-w-2xl mx-auto text-white leading-relaxed">
          Not just a meme. Not just AI.  
          <br />It’s your degenerate best friend on the blockchain 😎💥
        </p>
        <p className="text-md max-w-xl mx-auto mt-2 text-gray-400 leading-relaxed">
          Born to vibe. Built to moon. 🚀  
          <br />You in or just watching from the sidelines? 👀
        </p>
      </div>

      {/* CTA Buttons */}
      <div className="flex justify-center gap-4 mt-8 mb-20 flex-wrap">
        <a href="https://t.me/bmaxai" target="_blank" rel="noreferrer">
          <button className="bg-blue-600 hover:bg-yellow-700 px-6 py-3 rounded-2xl font-semibold text-white shadow-lg">
            Join Telegram
          </button>
        </a>

        <a href="https://x.com/bmax_ai" target="_blank" rel="noreferrer">
          <button className="bg-blue-600 hover:bg-yellow-700 px-6 py-3 rounded-2xl font-semibold text-white shadow-lg">
            Follow on X
          </button>
        </a>

        <a href="https://dexscreener.com/bsc/0x019d1caba988ce86b4067615d5069759e353b86b" target="_blank" rel="noreferrer">
          <button className="bg-blue-600 hover:bg-yellow-700 px-6 py-3 rounded-2xl font-semibold text-white shadow-lg">
            View on DEX SCREENER
          </button>
        </a>

        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x019d1caba988ce86b4067615d5069759e353b86b" target="_blank" rel="noreferrer">
          <button className="bg-blue-600 hover:bg-yellow-700 px-6 py-3 rounded-2xl font-semibold text-white shadow-lg">
            View on DEXTools
          </button>
        </a>

        <a href="https://bscscan.com/token/0x019d1caba988ce86b4067615d5069759e353b86b#balances" target="_blank" rel="noreferrer">
          <button className="bg-blue-600 hover:bg-yellow-700 px-6 py-3 rounded-2xl font-semibold text-white shadow-lg">
            View Holders
          </button>
        </a>
      </div>

      {/* About Section */}
      <section id="about" className="scroll-mt-24 py-20 px-4 bg-gradient-to-b from-[#0b0f1a] to-[#1a1a2e]">
        <h2 className="text-3xl font-bold text-yellow-500 text-center mb-4">About</h2>
        <p className="max-w-3xl mx-auto text-center text-gray-300">
          $BMAXAI ain't your average memecoin, buddy 💁‍♂️  
          <br />It’s the wild fusion of AI brains and degen vibes.
        </p>
        <p className="text-center text-gray-400 mt-4">
          Powered by community. Fueled by memes.  
          <br />Wrapped in futuristic anime sauce 🔮✨  
          <br />We’re not here to play… we’re here to **take over** 😤
        </p>

        {/* BMAX Chat Bubble */}
        <div className="mt-6 flex justify-center">
          <a 
            href="https://app.myshell.ai/chat/1742310700" 
            target="_blank" 
            rel="noreferrer"
            className="bg-yellow-600 hover:bg-yellow-700 text-white px-6 py-3 rounded-full shadow-lg flex items-center gap-2 text-sm md:text-base transition-all duration-300"
          >
            <span role="img" aria-label="chat" className="text-lg">💬</span>
            Vibe with Buddy MAX AI
          </a>
        </div>

        {/* Creator's Note */}
        <div className="mt-10 text-center text-gray-400 text-sm max-w-3xl mx-auto">
          <p className="italic">
            Built by a curious soul — <span className="text-white font-semibold">Vimal</span>, an Immigration expert turned crypto-AI explorer 🤓🚀
          </p>
          <p className="mt-2">
            Not a pro... Just a non-tech weirdo who followed the rabbit hole into blockchain & AI.  
            <br />$BMAXAI is my way of bringing human touch into AI. ❤️
          </p>
          <p className="mt-2">
            Wanna connect? Let’s vibe on 
            <a 
              href="https://www.linkedin.com/in/vimal-ganth-02a25b21/" 
              target="_blank" 
              rel="noreferrer"
              className="text-yellow-400 hover:underline ml-1"
            >
              LinkedIn 🔗
            </a>
          </p>
        </div>
      </section>

      {/* Tokenomics Section */}
      <section id="tokenomics" className="scroll-mt-24 py-20 px-4 bg-[#111827]">
        <h2 className="text-3xl font-bold text-yellow-500 text-center mb-4">Tokenomics</h2>
        <div className="max-w-3xl mx-auto text-center text-gray-300 space-y-2">
          <p><span className="text-yellow-400 font-semibold">BSC Chain</span></p>
          <p>Max Supply: <span className="text-white font-semibold">10,000,000 $BMAXAI</span></p>
          <p>Circulating Supply: <span className="text-white font-semibold">6,000,000 $BMAXAI</span></p>
          <p>Tokens Locked: <span className="text-white font-semibold">4,000,000 $BMAXAI</span></p>
          <p>Fair Launch: ✅</p>
          <p>Community Owned: 💯</p>
          <p>No Taxes, No VC, Just Vibes 🚀</p>
        </div>
        <div className="mt-10 text-center text-gray-400 text-sm">
          <p>Contract Address<span className="text-white font-mono break-all"></span></p>
          <p><span className="text-white font-mono break-all">0x019d1CaBA988cE86b4067615D5069759e353b86B</span></p>
        </div>
      </section>

      {/* Roadmap Section */}
      <section id="roadmap" className="scroll-mt-24 py-20 px-4 bg-gradient-to-t from-[#0b0f1a] to-[#1a1a2e]">
        <h2 className="text-3xl font-bold text-yellow-500 text-center mb-4">Roadmap</h2>
        {/* Phase 1 */}
        <div className="max-w-full max-w-[1600px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8 text-gray-300">

        {/* Phase 1 */}
        <div className="bg-[#1a1a2e] p-6 rounded-xl shadow-lg">
          <h3 className="text-lg font-bold text-yellow-500 mb-4 text-center">🚀 Phase 1: Genesis</h3>
          <div className="space-y-2 text-sm leading-relaxed">
            <p><span className="text-green-400">✅</span> Token Launch on BSC 🧬</p>
            <p><span className="text-green-400">✅</span> MyShell Buddy Agent Live 🤝</p>
            <p><span className="text-yellow-300">🟡</span> Training to be more human 🤖</p>
            <p><span className="text-yellow-300">🟡</span> Standalone MyShell Support Agent 🛠️</p>
            <p><span className="text-yellow-300">🟡</span> Cooking BSC project collabs 🔗</p>
            <p><span className="text-gray-400">🟡</span> Preparing for cross-chain collabs 🌉</p>
          </div>
          <p className="mt-4 text-yellow-400 text-xs text-center font-semibold border-t border-gray-700 pt-3">
            🎯 Target: 5K Community Members
          </p>
        </div>

        {/* Phase 2 */}
        <div className="bg-[#1a1a2e] p-6 rounded-xl shadow-lg">
          <h3 className="text-lg font-bold text-yellow-500 mb-4 text-center">🌱 Phase 2: Expansion</h3>
          <div className="space-y-2 text-sm leading-relaxed">
            <p><span className="text-gray-400">🔘</span> Partner with top BSC Projects 🤝</p>
            <p><span className="text-gray-400">🔘</span> Cross-chain collabs 🌐</p>
            <p><span className="text-gray-400">🔘</span> Boosting community vibes 🎉</p>
            <p><span className="text-gray-400">🔘</span> Build ecosystem 🧩</p>
          </div>
          <p className="mt-4 text-yellow-400 text-xs text-center font-semibold border-t border-gray-700 pt-3">
            🎯 Target: 10K Community Members
          </p>
        </div>

        {/* Phase 3 */}
        <div className="bg-[#1a1a2e] p-6 rounded-xl shadow-lg">
          <h3 className="text-lg font-bold text-yellow-500 mb-4 text-center">🧠 Phase 3: Specialization</h3>
          <div className="space-y-2 text-sm leading-relaxed">
            <p><span className="text-gray-400">🔘</span> BMAX Immigration AI Agent 🛂</p>
            <p><span className="text-gray-400">🔘</span> Enhance buddy bonding! 📚</p>
            <p><span className="text-gray-400">🔘</span> Explore new skills & domains 🛠️</p>
            <p><span className="text-gray-400">🔘</span> Integrate live news 📈</p>
          </div>
          <p className="mt-4 text-yellow-400 text-xs text-center font-semibold border-t border-gray-700 pt-3">
            🎯 Target: 50K Community Members
          </p>
        </div>

        {/* Phase 4 */}
        <div className="bg-[#1a1a2e] p-6 rounded-xl shadow-lg">
          <h3 className="text-lg font-bold text-yellow-500 mb-4 text-center">🏛️ Phase 4: Going Pro</h3>
          <div className="space-y-2 text-sm leading-relaxed">
            <p><span className="text-gray-400">🔘</span> Tier-2 CEX Listing 🏦</p>
            <p><span className="text-gray-400">🔘</span> Telegram-integrated Mobile App 📲</p>
            <p><span className="text-gray-400">🔘</span> Localized BMAX bots — multi-language 🌍</p>
            <p><span className="text-gray-400">🔘</span> Ecosystem expansion + reach 🎯</p>
          </div>
          <p className="mt-4 text-yellow-400 text-xs text-center font-semibold border-t border-gray-700 pt-3">
            🎯 Target: 100K Community Members
          </p>
        </div>

      </div>
      </section>
    </div>
  );
};

export default HomePage;